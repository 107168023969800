import React from 'react'
// import PropTypes from 'prop-types'
import Text from 'components/Text'
import {useI18next, useTranslation} from 'gatsby-plugin-react-i18next'
import Slider from 'react-slick'
import {graphql, useStaticQuery} from 'gatsby'
import Img from 'gatsby-image'
import {
  Client,
  ImageWrapper,
  Quote,
  QuotesIcon,
  QuoteWrapper,
  SliderItem,
  SliderOuter,
  TextBlock,
  Wrapper,
} from './ReviewsSection.styles'
import ContentContainer from '../ContentContainer'

const ReviewsSection = ({children, ...rest}) => {
  const {t} = useTranslation()
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
  }
  const {img1, img2, img3, img4, img5} = useStaticQuery(graphql`
    query {
      img1: file(relativePath: {eq: "sections/reviews/Cartensen Logo.png"}) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      img2: file(
        relativePath: {eq: "sections/reviews/Fischereihafen Restaurant Hamburg Logo.png"}
      ) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      img3: file(relativePath: {eq: "sections/reviews/Sikomed Logo.png"}) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      img4: file(relativePath: {eq: "sections/reviews/100_log.png"}) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      img5: file(relativePath: {eq: "sections/reviews/hsba_square1.png"}) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)
  return (
    <Wrapper {...rest} id="reviews">
      <ContentContainer>
        <Text content={t('sections.reviews.title')} size="titleXs" mb={15} />
        <Text content={t('sections.reviews.subtitle')} size="md" mb={0} />
        <SliderOuter>
          <Slider {...settings}>
            <div>
              <SliderItem>
                <ImageWrapper>
                  <Img src={img1} fluid={img1.childImageSharp.fluid} />
                </ImageWrapper>
                <TextBlock>
                  <QuotesIcon />
                  <QuoteWrapper>
                    <Quote content={t('sections.reviews.quote1')} />
                    <Client content={t('sections.reviews.client1')} />
                  </QuoteWrapper>
                </TextBlock>
              </SliderItem>
            </div>
            <div>
              <SliderItem>
                <ImageWrapper>
                  <Img src={img2} fluid={img2.childImageSharp.fluid} />
                </ImageWrapper>
                <TextBlock>
                  <QuotesIcon />
                  <QuoteWrapper>
                    <Quote content={t('sections.reviews.quote2')} />
                    <Client content={t('sections.reviews.client2')} />
                  </QuoteWrapper>
                </TextBlock>
              </SliderItem>
            </div>
            <div>
              <SliderItem>
                <ImageWrapper>
                  <Img src={img3} fluid={img3.childImageSharp.fluid} />
                </ImageWrapper>
                <TextBlock>
                  <QuotesIcon />
                  <QuoteWrapper>
                    <Quote content={t('sections.reviews.quote3')} />
                    <Client content={t('sections.reviews.client3')} />
                  </QuoteWrapper>
                </TextBlock>
              </SliderItem>
            </div>
            <div>
              <SliderItem>
                <ImageWrapper>
                  <Img src={img4} fluid={img4.childImageSharp.fluid} />
                </ImageWrapper>
                <TextBlock>
                  <QuotesIcon />
                  <QuoteWrapper>
                    <Quote content={t('sections.reviews.quote4')} />
                    <Client content={t('sections.reviews.client4')} />
                  </QuoteWrapper>
                </TextBlock>
              </SliderItem>
            </div>
            <div>
              <SliderItem>
                <ImageWrapper>
                  <Img src={img5} fluid={img5.childImageSharp.fluid} />
                </ImageWrapper>
                <TextBlock>
                  <QuotesIcon />
                  <QuoteWrapper>
                    <Quote content={t('sections.reviews.quote5')} />
                    <Client content={t('sections.reviews.client5')} />
                  </QuoteWrapper>
                </TextBlock>
              </SliderItem>
            </div>
          </Slider>
        </SliderOuter>
      </ContentContainer>
    </Wrapper>
  )
}

ReviewsSection.propTypes = {}
ReviewsSection.defaultProps = {}

export default ReviewsSection
