import styled from 'styled-components'
import Text from 'components/Text'
import Quotes from 'images/sections/reviews/quotes.inline.svg'
import sliderDotInactive from 'images/sections/reviews/slider-dot-inactive.svg'
import sliderDotActive from 'images/sections/reviews/slider-dot-active.svg'

export const Wrapper = styled.div(({theme}) => ({
  padding: '80px 0',

  [theme.breakpoints.down('tablet')]: {
    paddingTop: 60,
    paddingBottom: 100,
  },
}))

export const Title = styled(Text)(({theme}) => ({
  textTransform: 'uppercase',
}))

export const SliderOuter = styled.div(({theme}) => ({
  marginTop: 40,
  maxWidth: 765,
  width: '100%',

  '& .slick-dots': {
    bottom: -40,
  },

  '& .slick-dots li ': {
    marginLeft: 0,
    marginRight: 20,
    width: 5,
    height: 5,
  },

  '& .slick-dots li button': {
    padding: 0,
    width: 5,
    height: 5,
    background: `url(${sliderDotInactive}) center no-repeat`,
    backgroundSize: 'cover',
  },

  '& .slick-dots li.slick-active button': {
    background: `url(${sliderDotActive}) center no-repeat`,
    backgroundSize: 'cover',
  },

  '& .slick-dots li.slick-active button:before': {
    content: 'none',
  },

  '& .slick-dots li button:before': {
    content: 'none',
  },
}))

export const SliderItem = styled.div(({theme}) => ({
  display: 'flex',
  justifyContent: 'space-between',
  fontFamily: theme.fontFamilies.lato,

  [theme.breakpoints.down('tabletS')]: {
    flexDirection: 'column',
  },
}))

export const ImageWrapper = styled.div(({theme}) => ({
  marginRight: 30,
  maxWidth: 200,
  width: '100%',

  [theme.breakpoints.down('tabletS')]: {
    margin: '0 auto 20px',
    maxWidth: '300px',
  },
}))

export const TextBlock = styled.div(({theme}) => ({
  maxWidth: 495,
  width: '100%',
  display: 'flex',
}))

export const QuotesIcon = styled(Quotes)(({theme}) => ({
  marginTop: 5,
  marginRight: 16,
  flex: '0 0 13px',
}))

export const QuoteWrapper = styled.div(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
}))

export const Quote = styled(Text)(({theme}) => ({}))

export const Client = styled(Text)(({theme}) => ({
  marginBottom: 0,
}))
